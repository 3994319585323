import styles from '../css/newsListMobile.module.scss'
import { useHistory} from "react-router-dom";
import {useEffect,useState } from "react"
// swiper
import SwiperCore, {Autoplay} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react';
function NewsListBobile({list}) {
  const history = useHistory()
  return (
    <div>
      <Swipers list={list} />
      <div className={styles.container}>
        {
          list.map((item, index) => (
            <div key={index} className={ styles.item } onClick={() => history.push('/alldetail/' + list[index].id)}>
            <div className={styles.time}>
              <div>{item.updateDate && item.updateDate.slice(8,10)}</div>
              <div>{item.updateDate && item.updateDate.slice(0,7)}</div>
            </div>
            <div>
              <div className={ styles.title }>{item.title}</div>
              <div className={ styles.content }>{item.text}</div>
            </div>
        </div>
          ))
        }
      </div>
    </div>
  )
}

SwiperCore.use([Autoplay]);
const Swipers = (props) => {
  const list = props
  const [swiperList, setSwiperList] = useState([])
  const history = useHistory()
  useEffect(() => {
      setSwiperList(list.list.slice(0,5))
  }, [list])
  return (
    <div className={styles.swiper}>
      <Swiper autoplay loop pagination>
        {
          swiperList.map((val, index) => (
            <SwiperSlide key={val.id}>
              <div className={styles.swiperItem} >
                <div className={styles.swiperImg}>
                  <div className={styles.inner}>
                    <img src={'/api' + val.showPic} alt="swiperImg" />
                  </div>
                </div>
                <div className={styles.textBox}>
                  <div className={styles.text}>{val.title}</div>
                  <div className={styles.content}>{val.text}</div>
                  <div className={styles.detailwrap}>
                      <div className={styles.detail} onClick={() => history.push('/alldetail/' + val.id)}>查看详情</div>
                       <div className={styles.time}>{val.updateDate.slice(0,10)}</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </div>
  )
}
export default NewsListBobile
