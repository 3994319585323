import Banner from "./components/banner";
import Chanpin from "./components/chanpin";
import ChanpinMobile from "./components/chanpinMobile";
import HomeChanpin from "./components/homeChanpin";
import News from "./components/news";
import NewsMobile from "./components/newsMobile";
import HxMap from "./components/map";
import Hezuo from "./components/hezuo";
import {useViewport,AnimationFrames} from "../../useViewPort"
import {useLocation, useHistory} from "react-router-dom";
import {useEffect} from "react";

const Home = () => {
  let location = useLocation();
  let history = useHistory();

  useEffect(() => {
    if (location.search) {
      const index = location.search.split('?id=')[1]
      const dom = document.getElementById('offsetTop' + index)
      let num = dom.offsetTop - 150
      if (index === "2") {
        num += 80
      }
      // num 要移动到的位置
      AnimationFrames(num,20)
      history.push()
    }
  }, [location,history])
  const {width} = useViewport()
  return (
    <div style={{overflowX:'hidden'}}>
      {width > 1200 ?  <Banner/> :null}
      {width<420?<HomeChanpin/>:width > 1200 ? <Chanpin/> : <ChanpinMobile/>}
      {width > 1200 ? <News/> : <NewsMobile/>}
      {width > 1200 ? <HxMap/> : null}
      {width > 1200 ? <Hezuo/> : null}
    </div>
  )
}

export default Home;
