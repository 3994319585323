import styles from '../css/navsMobile.module.scss'
import {useHistory} from "react-router-dom";
import sthb from "../../../assets/h5/sthb.svg"
import jnjp from "../../../assets/h5/jnjp.svg"
import ywjc from "../../../assets/h5/ywjc.svg"
import dsjfx from "../../../assets/h5/dsjfx.svg"
function NewHead() {
  const history = useHistory()
  return(
    <div>
      <div className={styles.newhead}>
      </div>
      <div className={styles.cpfw}>
                <div onClick={() => history.push('/services?id=138')}>
                    <img src={sthb} alt="sthb" />
                    <div>生态环保</div>
                </div>
                <div  onClick={() => history.push('/services?id=139')}>
                    <img src={jnjp} alt="jnjp" />
                    <div>节能减排</div>
                </div>
                <div onClick={() => history.push('/services?id=141')}>
                    <img src={ywjc} alt="ywyjc" />
                    <div>运维与集成</div>
                </div>
                <div onClick={() => history.push('/services?id=140')}>
                    <img src={dsjfx} alt="dsjfx" />
                    <div>大数据分析</div>
                </div>
      </div>
    </div>
  )
}

export default NewHead
