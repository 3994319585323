import {useState, useEffect} from 'react'
import css from '../css/news.module.scss'
import {articleList} from '../../../axios'
// swiper
import SwiperCore, {Autoplay, Pagination} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react';
import {useHistory} from "react-router-dom";

SwiperCore.use([Autoplay, Pagination]);


const Swipers = () => {
  const [swiperList, setSwiperList] = useState([])
  const history = useHistory()

  useEffect(() => {
    articleList({
      channelId: 135,
      limit: 5,
      page: 1
    }).then(res => {
      setSwiperList(res.data)
    })
  }, [])

  return (
    <div className={css.swiper}>
      <Swiper autoplay loop pagination={{
        clickable: true,
        bulletClass: 'banner2',
        bulletActiveClass: 'banner2-active',
        modifierClass: 'banner2-',
      }}>
        {
          swiperList.map((val, index) => (
            <SwiperSlide key={index}>
              <div className={css.swiperItem} onClick={() => history.push('/alldetail/' + val.id)}>
                <img src={'/api' + val.showPic} alt="swiperImg" className={css.swiperImg}/>
                <div className={css.textBox}>
                  <div className={css.text}>{val.title}</div>
                  <div className={css.time}>{val.updateDate ? val.updateDate.slice(0, 10) : ''}</div>
                </div>
              </div>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </div>
  )
}


const navList = [
  {name: '企业新闻', index: 0, id: 135},
  {name: '行业动态', index: 1, id: 136}
]
const NewList = () => {
  const [swiperList, setSwiperList] = useState([])
  const [navIndex, setNavIndex] = useState(0)
  const [swiper, setSwiper] = useState(null)
  const history = useHistory()

  useEffect(() => {
    const p1 = articleList({channelId: navList[0].id, limit: 2, page: 1})
    const p2 = articleList({channelId: navList[1].id, limit: 2, page: 1})
    Promise.all([p1, p2]).then(res => {
      const list = res.map(val => val.data)
      setSwiperList(list)
    })
  }, [])

  return (
    <div className={css.newList}>
      <div className={css.navs}>
        {
          navList.map(item => (
              <div className={navIndex === item.index ? css.navItemActive : css.navItem}
                   onClick={() => {
                     swiper.slideTo(item.index)
                     setNavIndex(item.index)
                   }}
                   key={item.index}>
                {item.name}
              </div>
          ))
        }
        <div onClick={()=>{
          if(navIndex===0){
            history.push('/news?id=135')
          }else{
            history.push('/news?id=136')
          }
        }} className={css.more}>更多>></div>
      </div>
      <Swiper onSwiper={swiper => setSwiper(swiper)}>
        {
          swiperList.map((val, index) => (
            <SwiperSlide key={index}>
              <div className={css.newsList}>
                {
                  swiperList[index].map(item => (
                    <div className={css.newsItem} key={item.id} onClick={() => history.push('/alldetail/' + item.id)}>
                      <div className={css.newsTime}>
                        <div className={css.day}>{item.updateDate && item.updateDate.slice(8, 10)}</div>
                        <div>{item.updateDate && item.updateDate.slice(0, 7)}</div>
                      </div>
                      <div className={css.newMain}>
                        <div className={css.newTitle}>{item.title}</div>
                        <div className={css.newText}>{item.text}</div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </div>
  )
}

const News = () => (
  <div className={css.news} id='offsetTop1'>
    <div className={css.title}>
      <div>新闻动态</div>
      <div className={css.titleBottom}>
        <div className={css.b1}></div>
        <div className={css.b2}></div>
      </div>
    </div>
    <div className={css.main}>
      <Swipers/>
      <NewList/>
    </div>
  </div>
)

export default News;
