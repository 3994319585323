import css from './css/services.module.scss'
import a1 from '../../assets/home/a1.png'
import a2 from '../../assets/home/a2.png'
import a3 from '../../assets/home/a3.png'
import a4 from '../../assets/home/a4.png'
import b1 from '../../assets/home/b1.png'
import b2 from '../../assets/home/b2.png'
import b3 from '../../assets/home/b3.png'
import b4 from '../../assets/home/b4.png'
import a5 from '../../assets/home/a5.png'
import b5 from '../../assets/home/b5.png'
import { useState, useEffect } from 'react'
import { articleList } from "../../axios";
import { useLocation } from "react-router-dom";
import { useViewport } from '../../useViewPort'
import NewHead from "./components/newhead"
import { message } from 'antd';
const navList = [
  { name: '生态环保', text: 'environmental', id: "138", img1: a1, img2: b1 },
  { name: '节能减排', text: 'Industry News', id: "139", img1: a2, img2: b2 },
  { name: '运维集成', text: 'Energy saving', id: "141", img1: a3, img2: b3 },
  { name: '大数据分析与应用', text: 'Analysis of the', id: "140", img1: a4, img2: b4 },
  { name: '移动端', text: 'Mobile terminal', id: "152", img1: a5, img2: b5 },
]
const Head = () => {
  let location = useLocation();
  const { width } = useViewport()
  const [navIndex, setNavIndex] = useState('138')
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const changeNav = (navIndex) => {
    setNavIndex(navIndex)
    articleList({
      channelId: navIndex,
      limit: 5,
      page: 1
    }).then(res => {
        setList(res.data)
    })

  }
  const addList = () =>{
    setPage(page + 1)
    articleList({
      channelId: navIndex,
      limit: 5,
      page: page
    }).then(res => {
      if(res.data.length < 5){
        message.warning("没有更多了");
      }else{
        setList([...list,...res.data])
      }
    })
  }
  useEffect(() => {
    const id = location.search ? location.search.split('?id=')[1] : "138"
    changeNav(id)
  }, [location])

  return (
    <div>
      {
        width>875?
        <div className={css.head} style={{background:`url(/banner.png)`}}>
        <div className={css.navs}>
          <div className={css.nav}>
            {
              navList.map(item => (
                <div key={item.id}
                  className={navIndex === item.id ? css.active : css.navItem}
                  onClick={() => {
                    changeNav(item.id)
                  }}>
                  {
                    navIndex === item.id ? (
                      <img src={item.img1} alt="" />
                    ) : (
                      <img src={item.img2} alt="" />
                    )
                  }
                  <div>{item.name}</div>
                  <div className={css.active999}></div>
                </div>
              ))
            }
          </div>
        </div>
        <div className={css.bannerTitle}>
          <div className={css.slideInEllipticLeftFwd}>
            <p>
              <span className={css.p1span1}>产品服务</span>
              <span className={css.p1span2}>HUAN XIN NEWS</span>
            </p>
            <p className={css.p2}></p>
          </div>
          <p className={`${css.p3} ${css.slideInEllipticRightBck}`}>环信恒辉在生态环境信息化领域应用经验丰富，为客户提供稳定可靠的产品和服务</p>
        </div>
      </div>:<NewHead navIndex={navIndex} changeNav={changeNav}/>
      }
      {width>875?<Title navIndex={navIndex} />:null}
      {width>875? <Main list={list} />:<MainH5 list={list} addList={addList}/>}
    </div>
  )
}
const Main = (props) => {
  const { list} = props
  return (
    <div className={css.gird} style={{ marginTop: '50px' }}>
      {
        list.map((item, index) => (
          <div key={index} className={css.item} onClick={()=>{
              if(item.channelId=== 152) {
                window.location.href=item.subTitle
              }
          }}>
            <div className={css.img}>
              <img src={'/api' + item.showPic} alt="img" />
            </div>
            <div className={css.name}>{item.title}</div>
          </div>
        ))
      }
    </div>
  )
}
const MainH5 = (props)=>{

  const { list,addList } = props
  return (
    <div className={css.listH5wrap}>
      <div className={css.h5list}>
        {
          list.map(item=>(
            <div key={item.id} className={css.listitem}>
              <img className={css.listimg} src={'/api'+item.showPic} alt="" />
              <div className={css.listtxt}>
                <div className={css.tit}>{item.title}</div>
                <div className={css.txt}>{item.text}</div>
              </div>
            </div>
          ))
        }
      </div>
      <div className={css.btns1} onClick={()=>{
            addList()
          }}>查看更多</div>
    </div>
  )
}


const Title = (props) => {
  const item = (navList.filter(val => val.id === props.navIndex))[0]
  return (
    <div className={css.title}>
      <div>{item && item.name}</div>
      <div style={{ margin: '0 10px' }}> /</div>
      <div className={css.text}>{item && item.text}</div>
    </div>
  )
}

const Services = () => {
  const { width } = useViewport()
  return (
    <div>
    <Head />
    {width<415 ?null :
    <div className={css.connect}>
      <div className={css.connectImg}>
        <p className={css.connectText}>如果您对我们的产品或服务感兴趣 或有任何疑问 欢迎您立即联系我们</p>
        <div className={css.formInput}>
          <input placeholder="姓名" id={css.xm} />
          <input placeholder="电话" id={css.dh} />
          <input placeholder="邮箱" id={css.yx} />
          <button id={css.submit}>提交</button>
        </div>
      </div>
    </div>
    }
  </div>
  )
  }


export default Services
