import styles from '../css/navsMobile.module.scss'
import {useState} from 'react'
const navList = [
  {name: '企业新闻', text: 'Company news', id: "135"},
  {name: '行业动态', text: 'Industry News', id: "136"},
  {name: '公告信息', text: 'Announcement', id: "137"},
]
function NavsMobile({navIndex, changeNav}) {
  const [active,setActive] = useState('135')
  const setActiveFun=(val)=>{
    setActive(val)
  }
  return <div className={styles.container}>
    {
     navList.map(nav => (
        <div key={nav.id} className={active === nav.id?styles.active:styles.item} onClick={() => {changeNav(nav.id);setActiveFun(nav.id)}}>
          <span>{nav.name}</span>
        </div>
      ))
    }
  </div>
}
export default NavsMobile
