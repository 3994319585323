import css from '../css/newList.module.scss'
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.scss';
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SwiperCore, {Autoplay, Pagination} from "swiper";

SwiperCore.use([Autoplay, Pagination]);
const NewsList = (props) => {
  const { list, navIndex ,swiperList} = props

  const [swiper, setSwiper] = useState(null)
  const [index, setIndex] = useState(0)

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(0)
      setIndex(0)
    }
  }, [props.list, swiper])

  const history = useHistory()

  return (
    <div className={css.main}>
      <div className={css.lunImg} style={{
        display: navIndex === '137' ? 'none' : 'flex'
      }}>
        <div className={css.lb}>
          <Swiper autoplay
            onSwiper={swiper => setSwiper(swiper)}
            onSlideChange={swiper => {
              setIndex(swiper.activeIndex)
            }} pagination={{
              clickable: true,
              bulletClass: 'banner2',
              bulletActiveClass: 'banner2-active',
              modifierClass: 'banner2-',
            }}>
            {
              swiperList.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className={css.imgBox}>
                    <img src={'/api' + item.showPic} alt=""
                      style={{ width: '100%', height: "100%", objectFit: "cover" }} />
                  </div>
                </SwiperSlide>
              ))
            }
          </Swiper>
        </div>
        <div className={css.wz}>
          <p className={css.bt}>{swiperList[index] && swiperList[index].title}</p>
          <p className={css.nr}>{swiperList[index] && swiperList[index].text}</p>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span className={css.sj}>{swiperList[index] && swiperList[index].createDate.split(' ')[0]}</span>
            <span className={css.xq} onClick={() => history.push('/alldetail/' + list[index].id)}>详情</span>
          </div>
        </div>
      </div>
      {
        list.map((item, index) => (
          <div className={css.item} key={index} onClick={() => history.push('/alldetail/' + item.id)}>
            <div className={css.time}>
              <div className={css.day}>{item.updateDate && item.updateDate.slice(8, 10)}</div>
              <div>{item.updateDate && item.updateDate.slice(0, 7)}</div>
            </div>
            <div className={css.title}>
              <div className={css.h1}>{item.title}</div>
              <div className={css.newText}>{item.text}</div>
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default NewsList
