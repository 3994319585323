import img from '../assets/img.png'
import css from './css/footer.module.scss'
import {useViewport} from '../useViewPort'
import {getSiteInfo} from '../axios/index'
import {useEffect, useState} from 'react'
import {useHistory} from "react-router-dom";
import imgdz from "../assets/h5/dz.svg"
import imgdh from "../assets/h5/dh.svg"
// 扩展企业链接组件
const Kuozan = () => {
  const htmlList = [
    {
      name: '新闻动态',
      child: [
        {name: '企业新闻', router: '/news?id=135'},
        {name: '行业动态', router: '/news?id=136'},
        {name: '公告信息', router: '/news?id=137'},
        {name: '隐私协议',router:'http://www.hxhhtech.com/static/index.html'}
      ]
    },
    {
      name: '产品服务',
      child: [
        {name: '生态环保', router: '/services?id=138'},
        {name: '节能减排', router: '/services?id=139'},
        {name: '运维与集成', router: '/services?id=141'},
        {name: '大数据分析', router: '/services?id=140'},
        {name: '移动端', router: '/services?id=152'},
      ]
    },
    {
      name: '关于环信',
      child: [
        {name: "企业简介", router: '/about?id=0'},
        {name: "荣誉资质", router: '/about?id=1'},
        {name: "招聘英才", router: '/about?id=2'},
        {name: '联系我们', router: '/about?id=3'},
      ]
    }
  ]
  const history = useHistory()
  return (
    <div className={css.kuozan}>
      {
        htmlList.map((item, index) => (
          <div key={index}>
            <div className={css.title2}>{item.name}</div>
            {
              item.child.map((val, i) => (
                <div
                  key={i}
                  className={css.listItem}
                  onClick={() =>{
                    if(val.name==='隐私协议'){
                      window.open(val.router)
                    }
                    else{
                      history.push(val.router)
                    }
                    }}>
                  {val.name}
                </div>
              ))
            }
          </div>
        ))
      }
    </div>
  )
}

// 公司简介web
export const MainHx = (props) => {
  const {info} = props
  return (
    <div className={css.mainHx}>
      <div className={css.titie}>
        <div>{info.name}</div>
        <div className={css.ml}>智慧环保协同创新中心</div>
      </div>
      <div className={css.jianjie}>
        <img src={img} alt="img" className={css.img}/>
        <div>
          <div className={css.address}>
            <div className={css.text}>地址</div>
            <div className={css.text2}>
              <div className="mb">{info.address ? info.address.split('-')[0] : ''}</div>
              <div>{info.address ? info.address.split('-')[1]:''}</div>
            </div>
          </div>
          <div className={css.phone}>
            <div className={css.text}>热线</div>
            <div className={css.phoneNum}>{info.phone}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
// 公司简介h5
export const MainHxH5 = (props) => {
  const {info} = props
  return (
    <div className={css.h5Main}>
      <div className={css.h5Gs}>{info.name}</div>
      <div className={css.h5tit}>智慧环保协同创新中心</div>
      <div className={css.h5address}>
        <img src={imgdz} alt="" />
        <div>{info.address}</div>
      </div>
      <div className={css.h5phone} >
        <img src={imgdh} alt="" />
        <div>{info.phone}</div>
      </div>
    </div>
  )
}
const FooterH5 =(props)=>{
  const {info} = props
  return (
    <div className={css.huanxin}>
        &nbsp;
        &nbsp;
        <div>©2021 环信恒辉科技 版权所有</div>
        &nbsp;
        &nbsp;
      <a href="https://beian.miit.gov.cn/" target="_blank">陕ICP备2021002289号-1</a>
    </div>
  )
}
const FooterWeb =(props)=>{
  const {info} = props
  return (
    <div className={css.huanxin1}>
        &nbsp;
        &nbsp;
        <div>©2021 环信恒辉科技 版权所有</div>
        &nbsp;
        &nbsp;
      <a href="https://beian.miit.gov.cn/" target="_blank">陕ICP备2021002289号-1</a>
    </div>
  )
}
// 底部组件
const Footer = () => {
  const {width} = useViewport()
  const [info, setInfo] = useState({})
  useEffect(() => {
    getSiteInfo().then(res => setInfo(res.data))
  }, [])

  return (
    <div className={css.footer}>
      <div className={css.main}>
        <div className={css.mainwrap}>
          {width > 875 ? <MainHx info={info}/>:<MainHxH5 info={info}/>}
          {width > 875 ? <Kuozan info={info}/> : null}
        </div>
        {width>875?<FooterWeb info={info}/>:<FooterH5 info={info}/>}
      </div>

    </div>
  )
}

export default Footer
