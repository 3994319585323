import css from '../css/map.module.scss'

// 点位
const Dots = () => {
  const list = [
    {
      names:[
        '延安市生态环境保护信息化建设项目',
        '省生态环境厅多级运维项目',
        '省生态环境政务资源中心',
        '省生态环境厅环境信息共享交换平台',
        '省重点用能单位能耗监测预警信息系统',
        '省排污权交易系统',
        '省机动车尾气环保检测监控管理系统',
        '省机动车遥感监测监控管理系统',
        '省生态环境税管理系统',
        '省污染源排放总量减排分析考核管理系统',
        '省生态环境厅行政许可网上申报管理系统',
        '省环境保护投诉举报管理系统',
        '省环境保护部门随机抽查管理系统',
        '省环境综合信息可视化发布平台',
        '省绿色文明示范工程创建活动网络信息系统',
        '省空气质量实时发布系统'
      ],
      title:'陕西',
      id:1,
      titleDw:{
        top: "55.43%",
        left: "53.78%",
      },
      spanDw:{
        top: '100%',
        left: '-10%'
      }
    },
    {
      names:['省生态环境厅监控应急管理系统建设方案',],
      title:'甘肃',
      id:2,
      titleDw:{
        top: "52.25%",
        left: "47.24%"
      },
      spanDw:{
        top: '100%',
        left: '-10%'
      }
    },
    {
      names:['省生态环境厅监控应急管理系统建设方案',],
      title:'贵州',
      id:3,
      titleDw:{
        top: "74.35%",
        left: "51.16%"
      },
      spanDw:{
        top: '100%',
        left: '-10%'
      }
    },
    {
      names:['省重点污染源管理及主要污染物测算系统','市突发性环境污染事故应急决策支持系统'],
      title:'吉林',
      id:4,
      titleDw:{
        top: "27.22%",
        left: "80.95%"
      },
      spanDw:{
        top: '100%',
        left: '-10%'
      }
    },
    {
      names:['哈尔滨市生态环境局环境在线监控系统'],
      title:'黑龙江',
      id:5,
      titleDw:{
        top: "16.36%",
        left: "82.16%"
      },
      spanDw:{
        top: '100%',
        left: '-10%'
      }
    },
    {
      names:['焦作市生态环境局环境监控管理系统','安阳市生态环境局环境监控管理系统'],
      title:'河南',
      id:6,
      titleDw:{
        top: "54.57%",
        left: "63.58%"
      },
      spanDw:{
        top: '100%',
        left: '-10%'
      }
    },
    {
      names:['芜湖生态环境局环境自动监控管理系统', '淮南、淮北生态环境局环境自动监控管理系统', '蚌埠环境局环境自动监控管理系统'],
      title:'安徽',
      id:7,
      titleDw:{
        top: "60.07%",
        left: "67.97%"
      },
      spanDw:{
        top: '100%',
        left: '-10%'
      }
    },
    {
      names:['苏州新加坡工业园环境管理系统', '无锡新区环境监察管理系统'],
      title:'江苏',
      id:8,
      titleDw:{
        top: "56.166%",
        left: "72.82%"
      },
      spanDw:{
        top: '100%',
        left: '-10%'
      }
    },
    {
      names:['上海市环境保护与市容卫生监控平台'],
      title:'上海',
      id:9,
      titleDw:{
        top: "61.78%",
        left: "77.12%"
      },
      spanDw:{
        top: '100%',
        left: '-10%'
      }
    },
    {
      names:['省环境应急决策辅助分析系统',"宁波市环境应急监测决策系统"],
      title:'浙江',
      id:10,
      titleDw:{
        top: "69.23%",
        left: "74.88%"
      },
      spanDw:{
        top: '100%',
        left: '-10%'
      }
    },
    {
      names:['石狮市环境监测管理系统',"泉州市环境应急综合信息系统"],
      title:'福建',
      id:11,
      titleDw:{
        top: "76.43%",
        left: "70.77%"
      },
      spanDw:{
        top: '100%',
        left: '-10%'
      }
    }
  ]

  return (
    <div className={css.dots}>
      {
        list.map(item => (
          <div key={item.id} className={css.dot} style={item.titleDw}>
            <i className={css.i}></i>
            <span className={css.span}>{item.title}</span>
            <div className={css.content}>
              <div className={css.icon}></div>
              <div className={css.rings}>
                <div className={css.ring}></div>
                <div className={css.ring}></div>
                <div className={css.ring}></div>
              </div>
              <div className={css.line}></div>
              <div className={css.box}>
                <div className={css.titles}>{item.title}</div>
                {
                  item.names.map((name,index)=>(
                    <p className={css.p} key={index}>{name}</p>
                  ))
                }
              </div>
            </div>
          </div>
        ))
      }
    </div>
  )
}


const HxMap = () => (
  <div className={css.map} id="offsetTop2">
    <div className={css.title}>
      <div style={{color: '#E12224',ontWeight:'bold'}}>环信恒辉</div>
      <div style={{padding: '0 10px'}}>-</div>
      <div style={{fontWeight:'bold'}}>在应急指挥、生态环境业务应用等领域都有服务足迹</div>
    </div>
    <div className={css.text}>覆盖全国11个省、市、自治区</div>
    <div className={css.mapBox}>
      <Dots/>
      <div className={css.map2}></div>
    </div>
  </div>
)

export default HxMap
