import {useHistory} from "react-router-dom";
import css from '../css/chanpinMobile.module.scss'
import home from "../../../assets/h5/home.png"
import sthb from "../../../assets/h5/sthb.svg"
import jnjp from "../../../assets/h5/jnjp.svg"
import ywjc from "../../../assets/h5/ywjc.svg"
import dsjfx from "../../../assets/h5/dsjfx.svg"
import gg from "../../../assets/h5/gg.svg"
import {useEffect, useState} from 'react'
// swiper
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/components/navigation/navigation.scss';
import {articleList} from '../../../axios'
// 新闻列表
export const NewsList = () => {
    const [list, setList] = useState([])
    const history = useHistory()
    useEffect(() => {
      articleList({
        channelId: 137,
        limit: 5,
        page: 1
      }).then(res => {
        setList(res.data)
      })
    }, [])
    return (
      <div className={css.h5new}>
        <div >
          <Swiper autoplay={{delay:1500,reverseDirection:true}} loop={true} direction="vertical">
            {
              list.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className={css.h5tit} onClick={() => history.push('/alldetail/' + item.id)}>{item.title}</div>
                </SwiperSlide>
              ))
            }
          </Swiper>
        </div>
      </div>
    )
  }
const HomeChanpin = ()=>{
    const history = useHistory()
    return (
        <div className={css.h5homebox}>
            <img className={css.homeimg} src={home} alt="home" />
            {/* 公告 */}
            <div className={css.ggwrap}>
                <img src={gg} alt="gg" />
                <NewsList />
            </div>
            {/* 公告 */}
            <div className={css.cpfw}>
                <div onClick={() => history.push('/services?id=138')}>
                    <img src={sthb} alt="sthb" />
                    <div>生态环保</div>
                </div>
                <div  onClick={() => history.push('/services?id=139')}>
                    <img src={jnjp} alt="jnjp" />
                    <div>节能减排</div>
                </div>
                <div onClick={() => history.push('/services?id=141')}>
                    <img src={ywjc} alt="ywyjc" />
                    <div>运维与集成</div>
                </div>
                <div onClick={() => history.push('/services?id=140')}>
                    <img src={dsjfx} alt="dsjfx" />
                    <div>大数据分析</div>
                </div>
            </div>
        </div>
    )
}
export default HomeChanpin