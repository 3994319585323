import css from "./css/plus.module.scss"
const Plus = ()=>{
  return (
    <div className={css.wrap}>
      <div className={css.wrap_sy}>
        <div className={css.page3}></div>
        <div className={css.az}></div>
        <div className={css.pg}></div>
      </div>
      <div className={css.wrap_pt}></div>
    </div>
  )
}

export default Plus