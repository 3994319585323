import css3 from "../css/about3.module.scss"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import img1 from "../../../assets/about/ui.png"
import img2 from "../../../assets/about/web.png"
import img3 from "../../../assets/about/cs.png"
import Header from "./header"
import { useEffect, useState } from "react"
import { ZpwrapXQ } from "../../../axios/index.js"
import { Breadcrumb } from "antd";
import {useViewport} from "../../../useViewPort.js"
const swiperData = [
    { img: img1, name: 'UI设计师', id: '1207144378518121605' },
    { img: img2, name: 'WEB前端开发', id: "1207144378518121609" },
    { img: img3, name: 'IOS开发工程师', id: "1207144378518121608" },
    { img: img3, name: 'JAVA开发', id: "1207144378518121604" },
    { img: img3, name: 'GIS开发工程师', id: "1207144378518121607" },
]
// 招聘页
const Zpwrap = (props) => {
    let { setShowSwiper } = props
    const [article, setArticle] = useState([])
    useEffect(() => {
        ZpwrapXQ(props.id).then(res => {
            const result = res.data
            setArticle(result)
        })
    }, [props])
    return (
        <div className={css3.zpwrap}>
            <div className={css3.zptit}>
                <div className={css3.zptitwrap}>
                    <div className={css3.zptitle}>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">
                                首页
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <a onClick={() => setShowSwiper(true)} href="/">招聘英才</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>{article?.title} </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className={css3.name}>
                        <div className={css3.namebox}>
                            <span>{article?.title}</span>
                            <span>1</span>
                            <span>人</span>
                        </div>
                        <button className={css3.zpbutton}>
                            <a href="https://www.zhipin.com/gongsi/0ac9ef704e7601970XJ529S_Fw~~.html?ka=search_rcmd_company_name_0ac9ef704e7601970XJ529S_Fw~~_custompage" alt="">申请</a>
                        </button>
                    </div>
                    <p className={css3.zpaddress}>
                        <span>{article?.subTitle}</span>
                        <span>{(article?.updateDate)?.slice(0, 11)}</span>
                    </p>
                </div>
            </div>
            <div className={css3.zpyq}>
                <h2 className={css3.zwms}>职位描述</h2>
                <p className={css3.msnr}
                    dangerouslySetInnerHTML={{ __html: article?.marks }}
                >
                </p>
                <h2 className={css3.gwyq}>岗位要求</h2>
                <p className={css3.msnr}
                    dangerouslySetInnerHTML={{ __html: article?.content }}
                >
                </p>
            </div>
        </div>
    )
}
// 招聘轮播
const About3 = (props) => {
    const [swiper, setSwiper] = useState(null)
    const [id, setId] = useState(0)
    const [showSwiper, setShowSwiper] = useState(true)
    const {width} = useViewport()
    useEffect(() => {
        props.setHeaderShow(showSwiper)
        // eslint-disable-next-line
    }, [showSwiper])
    return (
        <>
            {
                showSwiper ? (
                    <div className={css3.about3}>
                        <Header ctit="招聘英才" etit="Talent recruitment" />
                        <div>
                            {
                                width>875?<div className={css3.swiperWrap}>
                                            <Swiper
                                                slidesPerView={3}
                                                spaceBetween={20}
                                                autoplay={true} loop={true}
                                                onSwiper={swiper => setSwiper(swiper)}
                                            >
                                                {
                                                    swiperData.map((item, index) => (
                                                        <SwiperSlide key={index}>
                                                            <div onClick={() => {
                                                                setId(item.id)
                                                                setShowSwiper(false)
                                                            }}>
                                                                <div className={css3.swiper1}>
                                                                    <img src={item.img} alt="" />
                                                                </div>
                                                                <div className={css3.swipername}>{item.name}</div>
                                                            </div>
                                                        </SwiperSlide>
                                                    ))
                                                }
                                            </Swiper>
                                            <div className={css3.navBoxleft}>
                                                <div className={css3.hoverQY}>
                                                    <div className={css3.navBoxleft1} onClick={() => swiper.slidePrev()}></div>
                                                </div>
                                                <div className={css3.hoverQY}>
                                                    <div className={css3.navBoxleft2} onClick={() => swiper.slideNext()}></div>
                                                </div>
                                            </div>
                                        </div>
                                :<H5About3 setHeaderShow={props.setHeaderShow} setSwiper={setSwiper} setShowSwiper={setShowSwiper} setId={setId}/>
                            }
                        </div>
                    </div >
                ) : (
                    <Zpwrap id={id} setShowSwiper={setShowSwiper} />
                )
            }

        </>
    )
}
const H5About3 = (props)=>{
    return (
        <div className={css3.swiperWrap}>
            <Swiper
                slidesPerView={1}
                spaceBetween={20}
                autoplay={true} loop={true}
                pagination
                onSwiper={swiper => props.setSwiper(swiper)}
            >
                {
                    swiperData.map((item, index) => (
                        <SwiperSlide key={index}>
                            <div onClick={() => {
                                props.setId(item.id)
                                props.setShowSwiper(false)
                            }}>
                                <div className={css3.swiper1}>
                                    <img src={item.img} alt="" />
                                    <div className={css3.swipername}>{item.name}</div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    )
}
export default About3
