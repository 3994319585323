import css from "../css/nav.module.scss"
import { useViewport } from '../../../useViewPort.js'
const NavTitle = () => {
    const { width } = useViewport()
    return (
        <div>
        {
            width>875? <div className={css.Navheader} style={{
                backgroundImage:`url('/banner2.png')`
            }}>
                <div className={css.bannerTitle}>
                    <div className={css.slideInEllipticLeftFwd}>
                        <p>
                            <span className={css.p1span1}>环信动态</span>
                            <span className={css.p1span2}>HUAN XIN NEWS </span>
                        </p>
                        <p className={css.p2}></p>
                    </div>
                    <p className={`${css.p3} ${css.slideInEllipticRightBck}`}>记录点滴进步 书写精彩篇章  环信与你一同见证</p>
                </div>
            </div>: <div className={css.Navheader1}>
            </div>
        }
        </div>
       
    )
}
export default NavTitle
