import css from "../css/about.module.scss"
import { about1 } from "../../../axios"
import { useEffect, useState } from "react"
import { useViewport } from '../../../useViewPort'
import qyjj from '../../../assets/about/H5about/qyjj.svg'
import qyfc from '../../../assets/about/H5about/qyfc.svg'
import hxtd from '../../../assets/about/H5about/hxtd.svg'
import qymb from '../../../assets/about/H5about/qymb.svg'
import gswz from '../../../assets/about/H5about/gswz.svg'
import icon from '../../../assets/about/H5about/icon.svg'


const About1 = () => {
    const {width} = useViewport()
    const [article, setArticle] = useState([])
    useEffect(() => {
        about1().then(res => {
            const temp = res.data.map(val => val.content)
            setArticle(temp)
        })
    }, [])
    return (
        <div>
        {
            width>875?<div>
                            <div className={css.con1} dangerouslySetInnerHTML={{ __html: article[1] }}></div>
                            <div className={css.con2} dangerouslySetInnerHTML={{ __html: article[0] }}></div>
                            <div className={css.con3} dangerouslySetInnerHTML={{ __html: article[2] }}></div>
                      </div>
            :<H5About1/>
        }
        </div>
       
    )
}
const H5About1 =()=>{
    const h5img = {
        qyjj,
        qyfc,
        hxtd,
        qymb,
        gswz,
        icon
    }
    const txt ='环信恒辉科技有限公司是专业从事节能减排信息化研发建设的高科技信息服务技术企业，是中国环境科学学会副理事长单位、陕西省环境科学学会理事单位、陕西省节能协会理事单位。围绕节能减排信息化建设，环信恒辉经历20年的耕耘，在集成电子技术、通信技术、数字信息化技术、模型分析技术、地理信息技术、虚拟化技术、大数据应用技术、VR仿真等多领域、多层次的应用技术，多源系统融合集成于生态环境业务管理中，通过大数据分析系统对积累的信息化数据进行清洗、融合、叠加应用形成了一系列的节能减排信息化自主知识产权的领先产品，并在全国多个节能减排领域里获得成功应用。研发以虚拟化大数据服务为主体的陕西环保政务云服务体系， 运行多套生态环境业务数据，为环境治理提供科学支撑平台。环信恒辉在机房建设、精密机房运维、多数据融合集成应用、网络信息安全等领域有着近20年丰富的服务经验和专业的运维服务体系，并在全国多信息化领域里有着多项成功案例。自主研发一体化多级运维管理系统，实现对环境信息化专网及安全建设无人值守智能化服务。'.split('。',5)
    return (
        <div className={css.h5wrap}>
            <div className={css.qyjj}>
                <img src={h5img.qyjj} alt="" />
                <img src={h5img.gswz} alt="" />
            </div>
            
            <div className={css.gsjj}>
                {txt.map((item)=>(
                    <div>{item}。</div>
                ))}
            </div>
            <div className={css.qymb}>
                <img src={h5img.qymb} alt="" />
            </div>
            <div className={css.qyfc}>
                <div>
                    <img src={h5img.icon} alt="" />
                    <h2>企业风采</h2>
                </div>
                <img src={h5img.qyfc} className={css.qyfcimg} alt="" />
            </div>
            <div className={css.hxtd}>
                <div>
                    <img src={h5img.icon} alt="" />
                    <h2>企业风采</h2>
                </div>
                <img src={h5img.hxtd} className={css.hxtdimg} alt="" />
            </div>
        </div>
    )
}
export default About1
