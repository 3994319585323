import css from "./css/plus.module.scss"
const Plus = ()=>{
  return (
    <div className={css.wrap}>
      {/*<div className={css.wrap_sy}>*/}
      {/*  <div className={css.page3}></div>*/}
      {/*  <div className={css.az}></div>*/}
      {/*  <div className={css.pg}></div>*/}
      {/*</div>*/}
      <div className={css.wrap_pt}></div>
      <div className={css.ios}>
        <div className={css.iosm}></div>
      </div>
        <div className={css.and}>
            <div className={css.andm}></div>
        </div>
    </div>
  )
}

export default Plus
