import {useState} from 'react'
import lianxi from '../../../assets/home/lianxi.png'
import css from '../css/chanpin.module.scss'
import {useHistory} from "react-router-dom";
import {Affix} from 'antd';

//联系我们
const Lianxi = () => {
  return (
    <Affix offsetTop={300}>
      <div className={css.lianxi}>
        <img src={lianxi} alt="lianxi"/>
      </div>
    </Affix>
  )
}


// 三角
const Sanjiao = () => (
  <div className={css.sanjiao}>
    <div className={css.sanjiao1}></div>
    <div className={css.sanjiao2}></div>
  </div>
)


// 产品及服务
const Chanpin = () => {
  const [index, setIndex] = useState(1)
  const history = useHistory()

  return (
    <div className={css.chanpin} id='offsetTop0'>
      <Lianxi/>
      <Sanjiao/>

      <div className={css.title}>
        <div id="div0">产品及服务</div>
        <div className={css.titleBottom}>
          <div className={css.b1}></div>
          <div className={css.b2}></div>
        </div>
      </div>

      <div className={css.main}>
        <div className={css.box1}>
          <div className={css.footer} title="生态环保" onClick={() => history.push('/services?id=138')}>
            <div className={css.tip} ></div>
            <div className={css.text} >
              环信恒辉科技专注生态环境信息化建设，20余年，在行业内积累了大量的优秀案例及产品，并得到了业主的广泛认可。
            </div>
          </div>
        </div>
        <div className={css.box2}>
          <div className={css.footer} title="节能减排"  onClick={() => history.push('/services?id=139')}>
            <div className={css.tip} ></div>
            <div className={css.text}>
              服务于城市重点能耗单位，为政府提供节能减排指标的基础信息，以及决策依据
            </div>
          </div>
        </div>
        <div className={css.box3}>
          <div className={css.footer} title="运维与集成"  onClick={() => history.push('/services?id=141')}>
            <div className={css.tip} ></div>
            <div className={css.text}>
              电子集成技术，精密机房施工，高端集成通讯
            </div>
          </div>
        </div>
        <div className={css.box4}>
          <div className={css.footer} title="大数据分析与应用"  onClick={() => history.push('/services?id=140')}>
            <div className={css.tip} ></div>
            <div className={css.text}>
              实现环保业务信息与关联信息的多维共享与交换存储，为生态环境建设提供大数据支撑
            </div>
          </div>
        </div>
        {/*<div className={index === 1 ? css.lefts : css.left}*/}
        {/*     onMouseMove={() => setIndex(1)}*/}
        {/*     onMouseLeave={() => setIndex(1)}*/}
        {/*     onClick={() => history.push('/services?id=138')}*/}
        {/*>*/}
        {/*  <div className={css.box}>*/}
        {/*    <img src={b1} alt="生态环保" className={css.img}/>*/}
        {/*    <div className={css.text} style={{color: '#7595AA'}}>生态环保</div>*/}
        {/*  </div>*/}
        {/*  <div className={css.box} style={{color: '#fff'}}>*/}
        {/*    <img src={a1} alt="生态环保" className={css.img}/>*/}
        {/*    <div className={css.textActive}*/}
        {/*         style={{margin: '40px 0 130px 0'}}>环信恒辉科技专注生态环境信息化建设，20余年，在行业内积累了大量的优秀案例及产品，并得到了业主的广泛认可。*/}
        {/*    </div>*/}
        {/*    <div className={css.text} style={{fontSize: '18px'}}>生态环保</div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className={css.right}>*/}
        {/*  <div className={css.top}>*/}
        {/*    <div className={index === 2 ? css.jns : css.jn}*/}
        {/*         onMouseMove={() => setIndex(2)}*/}
        {/*         onMouseLeave={() => setIndex(1)}*/}
        {/*         onClick={() => history.push('/services?id=139')}*/}
        {/*    >*/}
        {/*      <div className={css.box}>*/}
        {/*        <img src={b2} alt="节能减排" className={css.img}/>*/}
        {/*        <div className={css.text} style={{color: '#7595AA'}}>节能减排</div>*/}
        {/*      </div>*/}
        {/*      <div className={css.box} style={{color: '#fff'}}>*/}
        {/*        <img src={a2} alt="节能减排" className={css.img}/>*/}
        {/*        <div className={css.textActive}>服务于城市重点能耗单位，为政府提供节能减排指标的基础信息，以及决策依据</div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div className={index === 3 ? css.yws : css.yw}*/}
        {/*         onMouseMove={() => setIndex(3)}*/}
        {/*         onMouseLeave={() => setIndex(1)}*/}
        {/*         onClick={() => history.push('/services?id=141')}*/}
        {/*    >*/}
        {/*      <div className={css.box}>*/}
        {/*        <img src={b3} alt="运维与集成" className={css.img}/>*/}
        {/*        <div className={css.text} style={{color: '#7595AA'}}>运维与集成</div>*/}
        {/*      </div>*/}
        {/*      <div className={css.box} style={{color: '#fff'}}>*/}
        {/*        <img src={a3} alt="运维与集成" className={css.img}/>*/}
        {/*        <div className={css.textActive}>电子集成技术，精密机房施工，高端集成通讯</div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className={index === 4 ? css.fxs : css.fx}*/}
        {/*       onMouseMove={() => setIndex(4)}*/}
        {/*       onMouseLeave={() => setIndex(1)}*/}
        {/*       onClick={() => history.push('/services?id=140')}*/}
        {/*  >*/}
        {/*    <div className={css.box}>*/}
        {/*      <img src={b4} alt="大数据分析与应用" className={css.img}/>*/}
        {/*      <div className={css.text} style={{color: '#7595AA'}}>大数据分析与应用</div>*/}
        {/*    </div>*/}
        {/*    <div className={css.box} style={{color: '#fff'}}>*/}
        {/*      <img src={a4} alt="大数据分析与应用" className={css.img}/>*/}
        {/*      <div className={css.textActive}>实现环保业务信息与关联信息的多维共享与交换存储，为生态环境建设提供大数据支撑</div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
      <div className={css.centetText}>
        <div>一站式系统定制开发服务</div>
        <div style={{margin: '0 10px'}}>/</div>
        <div>One-stop Internet service</div>
      </div>
    </div>
  )
}

export default Chanpin
