import styles from '../css/navsMobile.module.scss'
import {useState,useEffect} from 'react'
const navList = [
  { name: '企业简介', text: 'Company news', id: "0" },
  { name: '荣誉资质', text: 'Industry News', id: "1" },
  { name: '招聘英才', text: 'recruitment', id: "2" },
  { name: '联系我们', text: 'Contact us', id: "3" },
]

function NavsMobile({navIndex, changeNav,location,history}) {
  const [active,setActive] = useState('0')
  const [status,setstatus] = useState({})
  const [IDID,setIDID] = useState('0')
  
  useEffect(()=>{
    const temp = navList.find(nav => nav.id === navIndex)
    setstatus(status)
    location.search = "?id="+temp.id
    const ID = location.search ? location.search.split('?id=')[1] : "0"
    setIDID(ID)
    // eslint-disable-next-line
  },[location, navIndex])

  const setActiveFun=(val)=>{
    setActive(val)
  }
  return(
   <>
     <div className={styles.imgabout} alt="" />
     <div className={styles.container}>
    {
     navList.map(nav => (
        <div key={nav.id} className={active === nav.id?styles.active:styles.item} onClick={() => {
              history.push('/about?id=' + IDID)
              changeNav(nav.id);
              setActiveFun(nav.id)
              }}>
              <span>{nav.name}</span>
            </div>
          ))
        }
      </div>
   </>
  ) 
 
}

export default NavsMobile
