import css from '../services/css/services.module.scss'
import { useState, useEffect } from 'react'
import About0 from "./components/about1.jsx"
import About1 from "./components/about2.jsx"
import About2 from "./components/about3.jsx"
import About3 from "./components/about4.jsx"
import css1 from "./css/about.module.scss"
import imgleft from "../../assets/about/aboutleft.png"
import { useViewport } from '../../useViewPort'
import NavsMobile from './components/newhead'
const AboutHx = () => {
  return (
    <>
      <div className={css.bannerTitle}>
        <div className={css.slideInEllipticLeftFwd}>
          <p>
            <span className={css.p1span1}>关于环信</span>
            <span className={css.p1span2}>ABOUT </span>
          </p>
          <p className={css.p2}></p>
        </div>
        <p className={`${css.p3} ${css.slideInEllipticRightBck}`}>记录点滴进步 书写精彩篇章 环信与你一同见证</p>
      </div>
      <div className={css1.leftimg}>
        <img src={imgleft} alt=""></img>
      </div>
    </>
  )
}

const HxZhaopin = () => {
  return (
    <>
      <div className={css.bannerTitle}>
        <div className={css.slideInEllipticLeftFwd}>
          <p>
            <span className={css.p1span1}>WELCOME</span>
            <span className={css.p1span2}></span>
          </p>
          <p className={css.p2}></p>
        </div>
        <p className={`${css.p3} ${css.slideInEllipticRightBck}`}>环信恒辉欢迎你</p>
      </div>
      <div className={css1.leftimg}>
        <img src={imgleft} alt=""></img>
      </div>
    </>
  )
}

const Head = (props) => {
  const { navIndex, history, show,location } = props
  const navList = [
    { name: '企业简介', text: 'Company news', id: "0" },
    { name: '荣誉资质', text: 'Industry News', id: "1" },
    { name: '招聘英才', text: 'recruitment', id: "2" },
    { name: '联系我们', text: 'Contact us', id: "3" },
  ]
  const { width } = useViewport()
  const [navIndex1, setNavIndex1] = useState('0')
  const changeNav1 = (navIndex1) => {
    setNavIndex1(navIndex1)
  }
  return (
    <div>
    {
      width>875?<div className={css1.head} style={{
        background:`url(/banner4.png)`
      }}>
        <div className={css1.navs}>
          <div className={css.nav}>
            {
              navList.map(item => (
                <div key={item.id}
                  className={navIndex === item.id ? css1.active : css1.navItem}
                  onClick={() => {
                    history.push('/about?id=' + item.id)
                  }}>
                  <div>{item.name}</div>
                  <div className={css1.text}>{item.text}</div>
                  <div className={css1.active999}></div>
                </div>
              ))
            }
          </div>
        </div>
        {
          show ? <AboutHx></AboutHx> : <HxZhaopin></HxZhaopin>
        }
      </div >:<NavsMobile navIndex={navIndex1}  changeNav={changeNav1}  location={location} history={history} />
    }
    </div>
  )
}

const About = (props) => {
  const { location, history } = props
  const [navIndex, setNavIndex] = useState("0")
  const [HeaderShow, setHeaderShow] = useState(true) // 控制招聘详情和header

  useEffect(() => {
    const id = location.search ? location.search.split('?id=')[1] : "0"
    setNavIndex(id)
    setHeaderShow(true)
  }, [location])
  const { width } = useViewport()
  return (
    <div style={{overflowX:'hidden'}}>
      <Head navIndex={navIndex} show={HeaderShow} history={history} location={location} />
      {
        width > 875 ?
        <div style={{ marginTop: '50px' }}>
          {[<About0 />, <About1 />, <About2 setHeaderShow={setHeaderShow} />, <About3 />][navIndex]}
        </div> :
      <div>
        {[<About0 />, <About1 />, <About2 setHeaderShow={setHeaderShow} />, <About3 />][navIndex]}
      </div>
      }
    </div>
  )
}

export default About
