import React, { useState, useEffect, createContext, useContext } from 'react'

const viewportContext = createContext({})
console.log(viewportContext)
export const ViewportProvider = ({ children }) => {
  // 顺带监听下高度，备用
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)

  const handleWindowResize = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize)
    return () => window.removeEventListener("resize", handleWindowResize)
  }, [])

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  )
}

export const useViewport = () => {
  const { width, height } = useContext(viewportContext)
  return { width, height }
}


export const AnimationFrames = (num,time)=>{
  let i = 0;
  const offCz = Math.abs(num - window.pageYOffset)
  let inter ;
  function fun (){
    const bu = offCz / time
    if (i < time) {
      if (window.pageYOffset > num) {
        window.scrollTo(0, window.pageYOffset - bu)
      } else {
        window.scrollTo(0, window.pageYOffset + bu)
      }
      i ++
      inter = requestAnimationFrame(fun)
    } else {
      cancelAnimationFrame(inter)
    }
  }
  fun()
}
