import css from "../css/nav.module.scss"
import { useLocation, Link } from "react-router-dom"
import { useEffect, useState } from "react"
import { articleInfo } from '../../../axios'
import { Breadcrumb } from "antd";
const Detail = () => {
  const location = useLocation()
  const [article, setArticle] = useState({})

  useEffect(() => {
    if (location.pathname.includes('/alldetail/')) {
      const id = location.pathname.split('/alldetail/')[1]
      articleInfo(id).then(res => {
        setArticle(res.data)
        console.log(res)
      })
    }
  }, [location])

  return (
    <>
      <div className={css.detailWrap}>
        <div className={css.detailnav}>
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              首页
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/news">新闻动态</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{article?.title} </Breadcrumb.Item>
          </Breadcrumb>
          <span className={css.dqlocation}>{null && article.title}</span></div>
        <div className={css.title}>{article.title}</div>
        <p className={css.time}>
          <span>发布时间: {(article?.updateDate)?.slice(0, 11)}</span> <span>浏览次数: {1204}</span>
        </p>
        {/*{article.showPic ?*/}
        {/*  <img className={css.inner} src={'/api' + article.showPic} alt=""/>*/}
        {/*  : null*/}
        {/*}*/}
        <div className={css.detailContent}
             style={{height: 'auto'}}
             dangerouslySetInnerHTML={{__html: article.content}}>
        </div>
      </div>

    </>
  );
}

export default Detail
