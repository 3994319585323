import styles from './css/headerMobile.module.scss'
import logo from '../assets/logo4.png'
import search from "../assets/h5/search.png"
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

const navs = [
  {name: '首页', router: '/',id:0},
  {name: '新闻动态', router: '/news',id:1},
  {name: '产品服务', router: '/services',id:2},
  {name: '关于环信', router: '/about',id:3},
]

function HeaderMobile () {
  const [show, setShow] = useState(false)
  const [navIndex, setnavIndex] = useState(0)
  const history = useHistory()
  return (
    <>
      <div className={styles.container}>
        <img src={logo} alt="logo"/>
        <img src={search} alt="" onClick={() => setShow(!show)} />
      </div>
      <div className={styles.wrap}>
      {navs.map((nav,index) => (
          <div className={nav.id===navIndex?styles.active:styles.item} key={nav.router} onClick={() => {
            setnavIndex(index)
            history.push(nav.router)
            setShow(false)
          }}>
            <span>{nav.name}</span>
          </div>
      ))}
      </div>

    </>
  )
}

export default HeaderMobile
