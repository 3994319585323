import {useState,useEffect} from 'react'
import css from '../css/newsMobile.module.scss'
import {articleList} from '../../../axios'
// swiper
import SwiperCore, {Autoplay} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react';
import imghzhb from "../../../assets/h5/hzhb.png"
import {useHistory} from "react-router-dom";

SwiperCore.use([Autoplay]);
const Swipers = () => {
  const [swiperList, setSwiperList] = useState([])
  const history = useHistory()
  useEffect(() => {
    articleList({
      channelId: 135,
      limit: 5,
      page: 1
    }).then(res => {
      setSwiperList(res.data)
    })
  }, [])
  return (
    <div className={css.swiper}>
      <Swiper autoplay loop pagination>
        {
          swiperList.map((val, index) => (
            <SwiperSlide key={val.id}>
              <div className={css.swiperItem} onClick={() => history.push('/alldetail/' + val.id)}>
                <div className={css.swiperImg}>
                  <div className={css.inner}>
                    <img src={'/api' + val.showPic} alt="swiperImg" />
                  </div>
                </div>
                <div className={css.textBox}>
                  <div className={css.text}>{val.title}</div>
                  <div className={css.time}>{val.updateDate.slice(0,10)}</div>
                </div>
              </div>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </div>
  )
}


const navList = [
  {name: '企业新闻', index: 0, id: 135},
  {name: '行业动态', index: 1, id: 136},
  {name: '公告信息', index: 2, id: 137},
]
const NewList = () => {
  const [swiperList, setSwiperList] = useState([])
  const [navIndex, setNavIndex] = useState(0)
  const [swiper, setSwiper] = useState(null)
  const history = useHistory()
  useEffect(() => {
    const p1 = articleList({channelId: navList[0].id, limit: 3, page: 1})
    const p2 = articleList({channelId: navList[1].id, limit: 3, page: 1})
    const p3 = articleList({channelId: navList[2].id, limit: 3, page: 1})
    Promise.all([p1,p2,p3]).then(res=>{
      const list = res.map(val=>val.data)
      setSwiperList(list)
      console.log(list);
    })
  }, [])
  return (
    <div className={css.newList}>
      <div className={css.navs}>
        {
          navList.map(item => (
            <div className={navIndex === item.index ? css.navItemActive : css.navItem}
                 onClick={() => {
                   swiper.slideTo(item.index)
                   setNavIndex(item.index)
                 }}
                 key={item.index}>
              {item.name}
            </div>
          ))
        }
      </div>
      <Swiper onSwiper={swiper => setSwiper(swiper)}>
        {
          swiperList.map((val,index)=>(
            <SwiperSlide key={index}>
              <div className={css.container}>
                {
                  swiperList[index].map((item,key) => (
                      <div key={key} className={ css.item } onClick={() => history.push('/alldetail/' + item.id)}>
                          <div className={css.time}>
                            <div>{item.updateDate && item.updateDate.slice(8,10)}</div>
                            <div>{item.updateDate && item.updateDate.slice(0,7)}</div>
                          </div>
                          <div>
                            <div className={ css.title }>{item.title}</div>
                            <div className={ css.content }>{item.text}</div>
                          </div>
                      </div>
                  ))
                }
              </div>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </div>
  )
}

const News = () => (
  <div className={css.news}>
    <div className={css.title}>
      <div>新闻动态</div>
      <div className={css.titleBottom}>
        <div className={css.b1}></div>
        <div className={css.b2}></div>
      </div>
    </div>
    <div className={css.main}>
      <Swipers/>
      <NewList/>
    </div>
    <div className={css.h5homehzhb}>
      <div className={css.h5hometit}>合作伙伴</div>
      <img style={{width:'100%',textAlign:'center'}} src={imghzhb} alt="" />
    </div>
  </div>
)
export default News;
