import Header from "./header"
import css from "../css/about.module.scss"
import img411 from "../../../assets/about/about4/dz.png"
import img412 from "../../../assets/about/about4/lx.png"
import img413 from "../../../assets/about/about4/hxyx.png"
import img1h5 from '../../../assets/about/H5about/dz.svg'
import img2h5 from '../../../assets/about/H5about/dh.svg'
import img3h5 from '../../../assets/about/H5about/yx.svg'
import ly from '../../../assets/about/H5about/lxwm.svg'
import Map from "./map"
import {useViewport} from "../../../useViewPort.js"
const formation = {
    ctit: '联系我们',
    etit: 'Announcement',
    text: '环信恒辉科技有限公司  是专业从事节能减排信息化研发建设的高科技信息服务技术企业，是中国环境科学学会副理事长单位、陕西省环境科学学会理事单位、陕西省节能协会理事单位。'
}
const list = [
    { img: img413, tit1: '环信地址', tit2: '西安市雁塔区旺座曲江F座2601/2602' },
    { img: img411, tit1: '联系我们', tit2: '400-8831-567' },
    { img: img412, tit1: '环信邮箱', tit2: 'contact@hxhhtech.com' },
]
const About4 = () => {
    const {width} = useViewport() 
    return (
        <>
            <Header {...formation}></Header>
            <div>
                {
                    width>875?<div>
                                 <div className={css.about4}>
                                    <div className={css.con41wrap}>
                                        {
                                            list.map((item, index) => (
                                                <div className={css.con41} key={index}>
                                                    <dl style={{
                                                        textAlign: 'center'
                                                    }}>
                                                        <dt style={{
                                                            marginTop: 45,
                                                            marginBottom: 28,
                                                        }}>
                                                            <img src={item.img} alt=""></img>
                                                        </dt>
                                                        <dd style={{
                                                            marginTop: 45,
                                                            marginBottom: 28,
                                                        }}>{item.tit1}</dd>
                                                        <dd>{item.tit2}</dd>
                                                    </dl>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className={css.con42wrap}>
                                                <div className={css.con42h1}>
                                                    <p className={css.p1}>给我们留言</p>
                                                    <p className={css.p2}>您的任何疑问或建议，都可通过留言反馈给我们</p>
                                                </div>
                                                <div className={css.inputwrap}>
                                                    <input placeholder="公司" className={css.input} />
                                                    <input placeholder="姓名" className={css.input} />
                                                    <input placeholder="电话" className={css.input} />
                                                    <input placeholder="邮箱" className={css.input} />
                                                    <textarea rows="5" cols="33" placeholder="留言" className={css.textarea}>

                                                    </textarea>
                                                    <input placeholder="验证码" className={css.input} />
                                                    <button className={css.button}>提交</button>
                                                </div>
                                            </div>
                                    </div>
                            </div>
                    :<H5About4/>
                }
            </div>
           
            <div >
                <Map/>
            </div>
        </>
    )
}
const H5About4 =()=>{
    const list = [
        { img: img1h5, tit1: '环信地址', tit2: '西安市雁塔区旺座曲江F座2601/2602' },
        { img: img2h5, tit1: '联系我们', tit2: '400-8831-567' },
        { img: img3h5, tit1: '环信邮箱', tit2: '1178667704@163.com' },
    ]
    return (
        <div>
            <div className={css.h5wrap4}>
            {
                list.map((item, index) => (
                    <div className={css.item4} key={index}>
                        <dl>
                            <dt>
                                <img src={item.img} alt=""></img>
                            </dt>
                            <dd className={css.dd1} style={{marginTop:'18px',paddingLeft:'90px'}}>{item.tit1}</dd>
                            <dd  className={css.dd2} style={{marginLeft:'12px',paddingLeft:'78px'}}>{item.tit2}</dd>
                        </dl>
                    </div>
                    
                ))
            }
        </div>
             <div className={css.h5about4wrap2}>
                <div className={css.h5con2}>
                    <img src={ly} alt="" />
                    <p className={css.h5p2}>您的任何疑问或建议，都可通过留言反馈给我们</p>
                </div>
                <div className={css.h5inputwrap}>
                    <input placeholder="公司" className={css.h5input} />
                    <input placeholder="姓名" className={css.h5input} />
                    <input placeholder="电话" className={css.h5input} />
                    <input placeholder="邮箱" className={css.h5input} />
                    <textarea rows="5" cols="33" placeholder="留言" className={css.h5textarea}>
                    </textarea>
                    <input placeholder="验证码" className={css.h5input} />
                    <button className={css.h5button}>提交</button>
                </div>
            </div>
        </div>
    )
} 
export default About4