import css from "../css/about.module.scss"
import { useEffect, useState } from "react"
import { about2 } from "../../../axios/index.js"
import {useViewport} from "../../../useViewPort.js"
import ryzs from "../../../assets/about/H5about/ryzs.svg" 
import ryfc from "../../../assets/about/H5about/ryfc.svg" 
import rjzz from "../../../assets/about/H5about/rjzz.svg" 
const About2 = () => {
    const {width} =useViewport()
    const [article, setArticle] = useState([])
    useEffect(() => {
        about2().then(res => {
            const temp = res.data.map(val => val.content)
            setArticle(temp)
        })
    }, [])
    return (
        <div>
        {
            width>875?
            <>
                <div className={css.about21} dangerouslySetInnerHTML={{ __html: article[2] }}></div>
                <div className={css.about22}>
                </div>
                <div className={css.about23}>
                    <p className={css.about23Text1}>荣誉风采</p>
                    <p className={css.about23Text2}>秉承诚信、服务、周到、创新理念，多次获得环保诚信企业、陕西省诚信企业示范单位、诚信AAAA级单位等社会荣誉</p>
                    <div className={css.about23img} dangerouslySetInnerHTML={{ __html: article[0] }}></div>
                </div>
                <div className={css.about24} dangerouslySetInnerHTML={{ __html: article[1] }}></div>
            </>
            :<H5About2/>
        }
        </div>
        
    )
}
const H5About2 = ()=>{
    return (
        <div className={css.h5wrap2}>
            <div classNamecss={css.ryfc}>
                <img src={ryfc} alt="" />
            </div>
            <div className={css.rjzz}>
                <div className={css.ryzzTxt}>
                    <div className={css.bg}></div>
                    <div className={css.txt}>
                            <div>荣誉风采</div>
                            <div>秉承诚信、服务、周到、创新理念，多次获得环保诚信企业、陕西省诚信企业示范单位、诚信AAAA级单位等社会荣誉</div>
                    </div>
                </div>
                <img className={css.rjzz} src={rjzz} alt="" />
                <img className={css.ryzs} src={ryzs} alt="" />
            </div>
        </div>
    )
}
export default About2