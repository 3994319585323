import a1 from '../../../assets/home/a1.png'
import a2 from '../../../assets/home/a2.png'
import a3 from '../../../assets/home/a3.png'
import a4 from '../../../assets/home/a4.png'
import css from '../css/chanpinMobile.module.scss'
import {useHistory} from "react-router-dom";

// 产品及服务
const ChanpinMobile = () => {
  const history = useHistory()

  return (
    <div className={css.chanpin}>
      <div className={css.title}>
        <div id="div0">产品及服务</div>
        <div className={css.titleBottom}>
          <div className={css.b1}></div>
          <div className={css.b2}></div>
        </div>
      </div>

      <div className={css.boxs}>
        <div className={css.box}  onClick={() => history.push('/services?id=138')}>
          <img src={a1} alt="" className={css.img} />
          <div className={css.textActive}>环信恒辉科技专注生态环境信息化建设，20余年，在行业内积累了大量的优秀案例及产品，并得到了业主的广泛认可。</div>
          <div className={css.text}>生态环保</div>
        </div>
        <div className={css.box}  onClick={() => history.push('/services?id=139')}>
          <img src={a2} alt="" className={css.img} />
          <div className={css.textActive}>服务于城市重点能耗单位，为政府提供节能减排指标的基础信息，以及决策依据</div>
          <div className={css.text}>节能减排</div>
        </div>
        <div className={css.box}  onClick={() => history.push('/services?id=141')}>
          <img src={a3} alt="" className={css.img} />
          <div className={css.textActive}>电子集成技术，精密机房施工，高端集成通讯</div>
          <div className={css.text}>运维与集成</div>
        </div>
        <div className={css.box}  onClick={() => history.push('/services?id=140')}>
          <img src={a4} alt="" className={css.img} />
          <div className={css.textActive}>实现环保业务信息与关联信息的多维共享与交换存储，为生态环境建设提供大数据支撑</div>
          <div className={css.text}>大数据分析与应用</div>
        </div>
      </div>
    </div>
  )
}

export default ChanpinMobile
