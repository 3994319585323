import React, { Fragment } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// 公共模块
import Header from "../layouts/header";
import Footer from "../layouts/footer";
// 页面路由
import Home from "../pages/home";
import News from "../pages/news";
import services from "../pages/services";
import About from "../pages/about/about";
import NetPlus from "../pages/NetPlus/index";
import TDCR from "../pages/TDCR/index";
import PWK from "../pages/PWK/index";
import Alldetail from "../pages/alldetail.jsx"
import { getConfirmation } from '../axios/axios'

const App = () => {
  return (
    <Fragment>
      <HashRouter getUserConfirmation={getConfirmation}>
        <Header />
        <Switch>
          <Route path='/' exact component={Home}></Route>
          <Route path='/news' exact component={News}></Route>
          <Route path='/services' exact component={services}></Route>
          <Route path='/about' exact component={About}></Route>
          <Route path='/netplus' exact component={NetPlus}></Route>
          <Route path='/TDCR' exact component={TDCR}></Route>
          <Route path='/PWK' exact component={PWK}></Route>
          <Route path='/alldetail/:id' exact component={Alldetail}></Route>
        </Switch>
        <Footer />
      </HashRouter>
    </Fragment>
  )
}

export default App;
