import css from '../css/hezuo.module.scss'
import hezuo from '../../../assets/home/hezuo.png'
import icon from '../../../assets/home/icon3.png'

const Hezuo = ()=>{

  return(
    <div className={css.hezuo} id="offsetTop3">
      <div className={css.title} style={{fontWeight:'bold'}}>环信合作伙伴</div>
      <div className={css.text}>环信恒辉科技期望与更多企业展开友好合作为环保行业提供更加专业且多元化解决方案</div>
      <img src={hezuo} alt="环信合作伙伴" className={css.img}/>
      <img src={icon} alt="icon" className={css.icon}/>
    </div>
  )
}

export default Hezuo
